import PropTypes from 'prop-types';
import {FC, memo} from 'react';
import {Button, InjectedFieldProps, PublicFieldProps, useDataProvider, useRecordContext} from 'react-admin';
import {useForm} from 'react-final-form';

export const SuggestLineupButton: FC<SuggestLineupButtonProps> = memo<SuggestLineupButtonProps>(
  props => {
    const {
      className,
      emptyText,
      source,
      ...rest
    } = props;
    const record = useRecordContext(props);
    const form = useForm();
    const dataProvider = useDataProvider();

    const run = async () => {
      const result = await dataProvider.getOne('suggestLineup', {id: record.id});
      form.getState().values.playerResults = (result.data as unknown as any[]).map(r => {
        return {
          uid: r.id
        }
      })
      form.reset();
    }
    return <Button label="Suggest Lineup" onClick={run} />;
  }
);

SuggestLineupButton.propTypes = {
  // @ts-ignore
  ...Typography.propTypes,
  source: PropTypes.string,
};

export interface SuggestLineupButtonProps
  extends PublicFieldProps,
  InjectedFieldProps {
}

export default SuggestLineupButton;
