import {AuthProvider, UserIdentity} from 'react-admin';

interface TypeAuthProvider extends AuthProvider { }

export class UdAuthProvider implements TypeAuthProvider {
  constructor(
    private url: string,
  ) { }

  private async cleanup(): Promise<void> {
    // Remove the ?code&state from the URL
    window.history.replaceState(
      {},
      window.document.title,
      window.location.origin
    );
  }

  public async login(params?: URLSearchParams): Promise<any> {
    // 1. Redirect to the issuer to ask authentication
    if (params === undefined || params.get === undefined || params.get('code') === undefined) {
      window.location.replace(`${this.url}/auth/google`);
      return Promise.resolve();
    }

    // 2. Got the response, we can now get ourselves a bearer token
    const response = await fetch(`${this.url}/auth/google/callback?${params.toString()}`, {
      method: 'GET',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
    });

    if (response.ok) {
      const json: any = await response.json()
      if (json.accessToken) {
        localStorage.setItem('token', json.accessToken);
        await this.cleanup();
        return Promise.resolve();
      }
    }

    await this.cleanup();
    return Promise.reject(response.statusText);
  }

  public async logout(params: any): Promise<string | false | void> {
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  public async checkAuth(params: any): Promise<void> {
    const token = localStorage.getItem('token');

    if (!token) {
      return Promise.reject()
    }

    return Promise.resolve()
  }

  public async checkError(error: any): Promise<void> {
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  public async getPermissions(params: any): Promise<any> {

  }

  public async getIdentity(): Promise<UserIdentity> {
    return null;
  }

}
