
import {BooleanField, BooleanInput, Create, Datagrid, Edit, EmailField, List, required, SimpleForm, TextField, TextInput} from 'react-admin';
import Defaults from './Defaults';

export const UsersList = (props: any) => (
  <List {...props} sort={Defaults.PLAYERS_SORT} perPage={Defaults.PER_PAGE}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EmailField source="mail" />
      <BooleanField source="admin" />
    </Datagrid>
  </List>
);

export const UserEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="mail" validate={required()} />
      <BooleanInput source="admin" validate={required()} />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <TextInput source="mail" validate={required()} />
      <BooleanInput source="admin" validate={required()} />
    </SimpleForm>
  </Create>
);
