
import {AutocompleteInput, BooleanField, BooleanInput, Create, Datagrid, Edit, List, ReferenceField, ReferenceInput, required, SimpleForm, TextField, TextInput} from 'react-admin';
import Defaults from './Defaults';

export const SeasonsList = (props: any) => (
  <List {...props} sort={Defaults.SEASONS_SORT} perPage={Defaults.PER_PAGE}>
    <Datagrid rowClick="edit">
      <TextField label="Name" source="seasonName" />
      <ReferenceField label="Type" source="seasonTypeId" reference="seasonTypes">
        <TextField source="seasonTypeName" />
      </ReferenceField>
      <BooleanField label="Playoffs" source="playoffs" />
    </Datagrid>
  </List>
);

export const SeasonEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Name" source="seasonName" validate={required()} />
      <ReferenceInput label="Type" source="seasonTypeId" reference="seasonTypes">
        <AutocompleteInput optionText="seasonTypeName" validate={required()} />
      </ReferenceInput>
      <BooleanInput label="Playoffs" source="playoffs" />
    </SimpleForm>
  </Edit>
);

export const SeasonCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm initialValues={{playoffs: false}}>
      <TextInput label="Name" source="seasonName" validate={required()}/>
      <ReferenceInput label="Type" source="seasonTypeId" reference="seasonTypes">
        <AutocompleteInput optionText="seasonTypeName" validate={required()}/>
      </ReferenceInput>
      <BooleanInput label="Playoffs" source="playoffs" />
    </SimpleForm>
  </Create>
);
