import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import {createStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {Login, useLogin} from 'react-admin';

const styles = ({spacing}) =>
  createStyles({
    button: {
      width: '100%',
    },
    icon: {
      marginRight: spacing.unit,
    },
  });

export const LoginPage = (props: any) => {
  return (
    <Login {...props}>
      <LoginForm classes={styles} />
    </Login>)
}

const LoginForm = ({classes}) => {
  const [loading, setLoading] = useState(false);
  const login = useLogin();

  useEffect(() => {
    console.log('useEffect')
    console.log(window.location)
    const {searchParams} = new URL(window.location.href);
    const code = searchParams.get('code');

    // If code is present, we came back from the provider
    if (code) {
      setLoading(true);
      login(searchParams);
    }
  }, [login]);

  const handleLogin = () => {
    setLoading(true);
    login({});
  };

  return (
    <div>
      <CardActions>
        <Button
          className={classes.button}
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleLogin}
          disabled={loading}
        >
          {loading && (
            <CircularProgress
              className={classes.icon}
              size={18}
              thickness={2}
            />
          )}
          Login With Google
        </Button>
      </CardActions>
    </div>
  );
}
