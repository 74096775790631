
import {Create, Datagrid, Edit, List, required, SimpleForm, TextField, TextInput} from 'react-admin';
import Defaults from './Defaults';

export const SeasonTypesList = (props: any) => (
  <List {...props} sort={{field: 'seasonTypeName', order: 'ASC'}} perPage={Defaults.PER_PAGE}>
    <Datagrid rowClick="edit">
      <TextField label="Name" source="seasonTypeName" />
    </Datagrid>
  </List>
);

export const SeasonTypeEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Name" source="seasonTypeName" validate={required()}/>
    </SimpleForm>
  </Edit>
);

export const SeasonTypeCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Name" source="seasonTypeName" validate={required()}/>
    </SimpleForm>
  </Create>
);
