
import {makeStyles} from '@material-ui/core/styles';
import {useEffect, useState} from 'react';
import {ArrayInput, AutocompleteInput, BooleanField, BooleanInput, Create, Datagrid, DateField, DateTimeInput, Edit, Error, FormTab, List, Loading, NumberField, NumberInput, ReferenceField, ReferenceInput, required, SimpleForm, TabbedForm, TextField, useDataProvider} from 'react-admin';
import Defaults from './Defaults';
import {DiamondsInput, OpponentsInput, SeasonsInput} from './ReferenceInputs';
import {SuggestLineupButton} from './SuggestLineupButton';
import TableFormIterator from './TableFormIterator';

export const GamesList = (props: any) => (
  <List {...props} sort={Defaults.GAMES_SORT} perPage={Defaults.PER_PAGE}>
    <Datagrid rowClick="edit">
      <DateField label="Date" source="date" showTime />
      <ReferenceField label="Season" source="seasonId" reference="seasons">
        <TextField source="seasonName" />
      </ReferenceField>
      <ReferenceField label="Opponent" source="opponentId" reference="opponents">
        <TextField source="opponentName" />
      </ReferenceField>
      <ReferenceField label="Diamond" source="diamondId" reference="diamonds">
        <TextField source="diamondName" />
      </ReferenceField>
      <BooleanField label="Home" source="home" />
      <NumberField label="Us" source="gameResult.us" />
      <NumberField label="Them" source="gameResult.them" />
    </Datagrid>
  </List>
);

const useStyles = makeStyles({
  entry: {
    width: '60px',
  },
  wideEntry: {
    width: '180px',
  },
});

export const GameEdit = (props: any) => {
  const classes = useStyles();
  return <Edit {...props} >
    <TabbedForm submitOnEnter={false}>
      <FormTab label="Details">
        <DateTimeInput label="Date" source="date" validate={required()} />
        <SeasonsInput validate={required()} />
        <OpponentsInput validate={required()} />
        <DiamondsInput validate={required()} />
        <BooleanInput label="Home" source="home" validate={required()} />
      </FormTab>
      <FormTab label="Results">
        <NumberInput label="Us" source="gameResult.us" validate={required()} />
        <NumberInput label="Them" source="gameResult.them" validate={required()} />
        <SuggestLineupButton source="playerResults" {...props} />
        <ArrayInput source="playerResults">
          <TableFormIterator classes={classes}>
            <ReferenceInput label="Player" source="uid" reference="users" sort={Defaults.PLAYERS_SORT} perPage={Defaults.PER_PAGE} className={classes.wideEntry}>
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <NumberInput label="AB" source="ab" validate={required()} defaultValue={0} />
            <NumberInput label="RBI" source="rbi" validate={required()} defaultValue={0} />
            <NumberInput label="R" source="r" validate={required()} defaultValue={0} />
            <NumberInput label="H" source="h" validate={required()} defaultValue={0} />
            <NumberInput label="2B" source="doubles" validate={required()} defaultValue={0} />
            <NumberInput label="3B" source="triples" validate={required()} defaultValue={0} />
            <NumberInput label="HR" source="hr" validate={required()} defaultValue={0} />
            <NumberInput label="BB" source="bb" validate={required()} defaultValue={0} />
          </TableFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit >
}

export const GameCreate = (props: any) => {
  const dataProvider = useDataProvider();
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getOne('config', {id: 0})
      .then(({data}) => {
        setConfig(data as any);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [dataProvider]);

  if (loading) {
    return <Loading />;
  } else if (error) {
    return <Error />;
  } else {
    const gameInitialValues = {date: new Date(), home: false, doubleHeader: true, seasonId: config[0].activeSeason}
    return (
      <Create {...props}>
        <SimpleForm initialValues={gameInitialValues}>
          <DateTimeInput label="Date" source="date" validate={required()} />
          <SeasonsInput validate={required()} />
          <OpponentsInput validate={required()} />
          <DiamondsInput validate={required()} />
          <BooleanInput label="Home" source="home" />
          <BooleanInput label="Automatically add double header" source="doubleHeader" defaultChecked={true} />
        </SimpleForm>
      </Create>
    );
  }
}
