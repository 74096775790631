
import {BooleanField, BooleanInput, Create, Datagrid, Edit, List, required, SimpleForm, TextField, TextInput} from 'react-admin';
import Defaults from './Defaults';

export const DiamondsList = (props: any) => (
  <List {...props} sort={Defaults.DIAMONDS_SORT} perPage={Defaults.PER_PAGE}>
    <Datagrid rowClick="edit">
      <TextField label="Name" source="diamondName" />
      <BooleanField label="Fenced" source="diamondFenced" />
    </Datagrid>
  </List>
);

export const DiamondEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Name" source="diamondName" validate={required()} />
      <BooleanInput label="Fenced" source="diamondFenced" />
    </SimpleForm>
  </Edit>
);

export const DiamondCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm initialValues={{diamondFenced: false}}>
      <TextInput label="Name" source="diamondName" validate={required()} />
      <BooleanInput label="Fenced" source="diamondFenced" />
    </SimpleForm>
  </Create>
);
