import {cloneDeep} from 'lodash';
import moment from 'moment';
import {convertLegacyDataProvider, CreateParams, CreateResult, DataProvider, DeleteManyParams, DeleteManyResult, DeleteParams, DeleteResult, GetListParams, GetListResult, GetManyParams, GetManyReferenceParams, GetManyReferenceResult, GetManyResult, GetOneParams, GetOneResult, Record, UpdateManyParams, UpdateManyResult, UpdateParams, UpdateResult} from 'react-admin';
import lb4Provider from 'react-admin-lb4';

interface TypedDataProvider extends DataProvider { }

export class UdDataProvider implements TypedDataProvider {
  private parentProvider: DataProvider;

  constructor(url: string) {
    const fetchReplace = async (input: RequestInfo, init: RequestInit = {}): Promise<Response> => {
      const token: string = localStorage.getItem('token');
      if (token) {
        const requestHeaders: HeadersInit = new Headers(init.headers);
        requestHeaders.set('Authorization', `Bearer ${token}`);
        init.headers = requestHeaders
      }
      return fetch(input, init);
    }

    this.parentProvider = convertLegacyDataProvider(lb4Provider(url, () => { }, '_id', 'id', fetchReplace));
  }

  public async getList<RecordType extends Record = Record>(resource: string, params: GetListParams): Promise<GetListResult<RecordType>> {
    return this.parentProvider.getList(resource, params);
  }

  public async getOne<RecordType extends Record = Record>(resource: string, params: GetOneParams): Promise<GetOneResult<RecordType>> {
    return this.parentProvider.getOne(resource, params);
  }

  public async getMany<RecordType extends Record = Record>(resource: string, params: GetManyParams): Promise<GetManyResult<RecordType>> {
    return this.parentProvider.getMany(resource, params);
  }

  public async getManyReference<RecordType extends Record = Record>(resource: string, params: GetManyReferenceParams): Promise<GetManyReferenceResult<RecordType>> {
    return this.parentProvider.getManyReference(resource, params);
  }

  public async update<RecordType extends Record = Record>(resource: string, params: UpdateParams<any>): Promise<UpdateResult<RecordType>> {
    if (resource === 'games' && (params.data.gameResults || params.data.playerResults)) {
      return this.handleGameUpdate(params);
    }

    return this.parentProvider.update(resource, params);
  }

  // Could import the model and have some types here
  private lookupPreviousResult(playerResult: any, previousData: any): any {
    if (playerResult.id) {
      for (let lookup of previousData.playerResults) {
        if (playerResult.id === lookup.id) {
          return lookup;
        }
      }
    }

    return null
  }

  private async handleGameCreate<RecordType extends Record = Record>(resource: string, params: CreateParams<any>): Promise<CreateResult<RecordType>> {
    // We add a special field to the create that allows a double header game to be created.
    // If this field is set, we do a second game request with home team inverted and 1h15m added to the game time
    if (params.data.doubleHeader) {
      const originalDate: moment.Moment = moment.utc(params.data.date)

      delete params.data.doubleHeader
      let additionalRequest: CreateParams<any> = cloneDeep(params)

      additionalRequest.data.home = !additionalRequest.data.home
      additionalRequest.data.date = originalDate.add('75', 'm')

      await this.parentProvider.create(resource, additionalRequest)
    }

    delete params.data.doubleHeader

    return this.parentProvider.create(resource, params);
  }

  private async handleGameUpdate<RecordType extends Record = Record>(params: UpdateParams<any>): Promise<UpdateResult<RecordType>> {
    // Game updates are special, as they include both the game results as well
    // as the batting results for each player. Given the way that react-admin
    // publishes updates from the edit page, the easiest way to handle these
    // is to hook in our own data provider that recognizes this and
    // breaks out the calls to loopback accordingly

    // Push the game result
    params.data.gameResult.gameId = params.data.id
    if (params.previousData.gameResult) {
      await this.update('gameResults', {
        data: params.data.gameResult,
        previousData: params.previousData.gameResult,
        id: params.previousData.gameResult.id
      });
    } else {
      await this.create('gameResults', {
        data: params.data.gameResult,
      });
    }

    let resultsCount = params.data.playerResults.length;
    for (let i = 0; i < resultsCount; i++) {
      let playerResult = params.data.playerResults[i]
      playerResult.gameId = params.data.id
      playerResult.battingOrder = i + 1
      playerResult.battedAfter = params.data.playerResults[i === 0 ? (resultsCount - 1) : i - 1].uid;
      playerResult.battedBefore = params.data.playerResults[i === (resultsCount - 1) ? 0 : i + 1].uid;
      playerResult.beer = 0
      let previousResult = this.lookupPreviousResult(playerResult, params.previousData);
      if (previousResult) {
        await this.update('gamePlayerResults', {
          data: playerResult,
          previousData: previousResult,
          id: previousResult.id
        });
      } else {
        await this.create('gamePlayerResults', {
          data: playerResult,
        });
      }
    }

    // We might have some users that were deleted, need to clean them up
    // previous data ids - new ids
    // Push the player results

    // Remove the values from the record and update
    delete params.data.gameResult;
    delete params.data.playerResults;
    delete params.previousData.gameResult;
    delete params.previousData.playerResults;
    return this.update('games', params);
  }

  public async updateMany(resource: string, params: UpdateManyParams<any>): Promise<UpdateManyResult> {
    return this.parentProvider.updateMany(resource, params);
  }

  public async create<RecordType extends Record = Record>(resource: string, params: CreateParams<any>): Promise<CreateResult<RecordType>> {
    if (resource === 'games') {
      return this.handleGameCreate(resource, params);
    }

    return this.parentProvider.create(resource, params);
  }

  public async delete<RecordType extends Record = Record>(resource: string, params: DeleteParams): Promise<DeleteResult<RecordType>> {
    return this.parentProvider.delete(resource, params);
  }

  public async deleteMany(resource: string, params: DeleteManyParams): Promise<DeleteManyResult> {
    return this.parentProvider.deleteMany(resource, params);
  }

}
