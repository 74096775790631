
class Defaults {
  static PER_PAGE: number = 1000;

  static DIAMONDS_SORT = {field: 'diamondName', order: 'ASC'};
  static GAMES_SORT = {field: 'date', order: 'DESC'};
  static OPPONENTS_SORT = {field: 'opponentName', order: 'ASC'};
  static SEASONS_SORT = {field: 'seasonName', order: 'ASC'};
  static PLAYERS_SORT = {field: 'name', order: 'ASC'};
}

export default Defaults;
