import {AutocompleteInput, ReferenceInput} from 'react-admin';
import Defaults from './Defaults';

export const OpponentsInput = (props: any) => (
  <ReferenceInput {...props}
  label={props.label || "Opponent"}
  source={props.source || "opponentId"}
  reference={props.reference || "opponents"}
  sort={props.sort || Defaults.OPPONENTS_SORT}
  perPage={props.perPage || Defaults.PER_PAGE}>
    <AutocompleteInput optionText="opponentName" />
  </ReferenceInput>
);

export const SeasonsInput = (props: any) => (
  <ReferenceInput
    {...props}
    label={props.label || "Season"}
    source={props.source || "seasonId"}
    reference={props.reference || "seasons"}
    sort={props.sort || Defaults.SEASONS_SORT}
    perPage={props.perPage || Defaults.PER_PAGE}>
    <AutocompleteInput optionText="seasonName" />
  </ReferenceInput>
);

export const DiamondsInput = (props: any) => (
  <ReferenceInput {...props}
    label={props.label || "Diamonds"}
    source={props.source || "diamondId"}
    reference={props.reference || "diamonds"}
    sort={props.sort || Defaults.DIAMONDS_SORT}
    perPage={props.perPage || Defaults.PER_PAGE}>
    <AutocompleteInput optionText="diamondName" />
  </ReferenceInput>
);

export const PlayersInput = (props: any) => (
  <ReferenceInput {...props}
  label={props.label || "Player"}
  source={props.source || "uid"}
  reference={props.reference || "users"}
  sort={props.sort || Defaults.PLAYERS_SORT}
  perPage={props.perPage || Defaults.PER_PAGE}>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>
);



