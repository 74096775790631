
import {Create, Datagrid, Edit, List, required, SimpleForm, TextField, TextInput} from 'react-admin';
import Defaults from './Defaults';

export const OpponentsList = (props: any) => (
  <List {...props} sort={Defaults.OPPONENTS_SORT} perPage={Defaults.PER_PAGE}>
    <Datagrid rowClick="edit">
      <TextField label="Name" source="opponentName" />
    </Datagrid>
  </List>
);

export const OpponentEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Name" source="opponentName" validate={required()}/>
    </SimpleForm>
  </Edit>
);

export const OpponentCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Name" source="opponentName" validate={required()}/>
    </SimpleForm>
  </Create>
);
