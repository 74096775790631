import React, {Component} from 'react';
import {Admin, Resource} from 'react-admin';
import {UdAuthProvider} from './auth/AuthProvider';
import {LoginPage} from './auth/Login';
import {ConfigEdit, ConfigList} from './Config';
import {DiamondCreate, DiamondEdit, DiamondsList} from './Diamonds';
import {GameCreate, GameEdit, GamesList} from './Games';
import {OpponentCreate, OpponentEdit, OpponentsList} from './Opponents';
import {SeasonCreate, SeasonEdit, SeasonsList} from './Seasons';
import {SeasonTypeCreate, SeasonTypeEdit, SeasonTypesList} from './SeasonTypes';
import {UdDataProvider} from './UdDataProvider';
import {UserCreate, UserEdit, UsersList} from './Users';

const config = {
  scheme: process.env.REACT_APP_API_SCHEME ? process.env.REACT_APP_API_SCHEME : "http",
  host: process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : "localhost",
  port: process.env.REACT_APP_API_PORT ? process.env.REACT_APP_API_PORT : "3000"
}

class Main extends Component {
  render() {
    const url: string = `${config.scheme}://${config.host}:${config.port}/api`
    return (
      <Admin loginPage={LoginPage} authProvider={new UdAuthProvider(url)} dataProvider={new UdDataProvider(url)}>
        <Resource options={{label: "Config"}} name="config" list={ConfigList} edit={ConfigEdit} />
        <Resource options={{label: "Users"}} name="users" list={UsersList} edit={UserEdit} create={UserCreate} />
        <Resource options={{label: "Season Types"}} name="seasonTypes" list={SeasonTypesList} edit={SeasonTypeEdit} create={SeasonTypeCreate} />
        <Resource options={{label: "Seasons"}} name="seasons" list={SeasonsList} edit={SeasonEdit} create={SeasonCreate} />
        <Resource options={{label: "Diamonds"}} name="diamonds" list={DiamondsList} edit={DiamondEdit} create={DiamondCreate} />
        <Resource options={{label: "Opponents"}} name="opponents" list={OpponentsList} edit={OpponentEdit} create={OpponentCreate} />
        <Resource options={{label: "Games"}} name="games" list={GamesList} edit={GameEdit} create={GameCreate} />
        <Resource name="gameResults" />
        <Resource name="suggestLineup" />
      </Admin>
    );
  }
}

export default Main
