
import {Datagrid, Edit, List, ReferenceField, SimpleForm, TextField} from 'react-admin';
import {SeasonsInput} from './ReferenceInputs';

export const ConfigList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField label="Active Season" source="activeSeason" reference="seasons">
        <TextField source="seasonName" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const ConfigEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <SeasonsInput label="Active Season" source="activeSeason" />
    </SimpleForm>
  </Edit>
);
